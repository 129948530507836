import React from 'react';
import * as Icon from "react-feather";
import { useLocation } from "react-router-dom";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Dropdown, Layout, Menu } from 'antd';
import { FaFileAlt, FaRegHandPaper } from 'react-icons/fa';

const DashboardHeader = ({ collapsed, toggle, history, currentDateTime, userName, accountDetails }) => {
    const { Header } = Layout;
    const location = useLocation();
    const menu = (
        <Menu>
            <Menu.Item>
                <a href="#" onClick={() => history.push("/profile")}>
                    <span><Icon.User size={14} className="mr-50" /></span>
                    <span> Edit Profile</span>
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="#"
                  onClick={() => history.push("/account-settings")} >
                    <span><Icon.Settings size={14} className="mr-50" /></span>
                    <span> Account Settings</span>
                </a>
            </Menu.Item>
            <Menu.Item>
                <a href="#" onClick={() => logOut()}>
                    <span> <Icon.Power size={14} className="mr-50" /></span>
                    <span>  Logout</span>
                </a>
            </Menu.Item>
        </Menu>
    );
    function logOut() {
        localStorage.removeItem('isUserLoggedIn');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('userIpAddress');
        localStorage.removeItem('permissionTypes');
        localStorage.removeItem('subscriptionDetails');
        localStorage.removeItem('marketServicesList');
        localStorage.removeItem('sdwan');
        localStorage.removeItem('svb');
        // localStorage.clear();
        sessionStorage.removeItem('zoomSubscribe');

        // sessionStorage.clear();
        window.location.href= "/login";
    }
    
  return (
    <Header className="site-layout-background" style={{ padding: 0, display: "flex", alignItems: "center", gap: "2.5rem", justifyContent: "space-between" }}>
                        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger',
                            onClick: toggle,
                        })}
                        
                        <span className="current-date"><b>Local:  <span className='text-color-secondary'>{currentDateTime}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a href="#" onClick={() => history.push("/ticket")}>
                                    <span><FaRegHandPaper size={16} style={{color:"black"}} /></span>&nbsp;&nbsp;&nbsp;
                                </a>
                                <a href="#" onClick={() => history.push("/quick-help")}>
                                    <span><Icon.HelpCircle  size={16} style={{color:"black"}} /></span>&nbsp;&nbsp;&nbsp;&nbsp;
                                </a>
                                <a href="https://docs.youcloudwan.com/user-guides/" onClick={() => history.push()}>
                                    <span><FaFileAlt size={16} style={{color:"black"}} /></span>
                                </a>
                        </span> </b></span>
                        <Dropdown overlay={menu} placement="bottomCenter" arrow={true}>
                            <span><span style={{ fontWeight: 600, fontSize: 13, color: "#626262" }}>{userName}</span> <br />
                                <span style={{ fontSize: 12 }}>{accountDetails?.orgName}</span>
                            </span>
                        </Dropdown>
                    </Header>
  )
}

export default DashboardHeader