import RouterConfig from "../router";
import Store from '../store/Store'

const App = () => {
  return (
    <Store>
      <RouterConfig />
    </Store>
  )
}

export default App;