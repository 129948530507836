import React from 'react';
import ReactDOM from 'react-dom';
import "antd/dist/antd.css";
import './css/style.sass';
import App from './pages/App';



ReactDOM.render(<App />, document.getElementById('root'));

