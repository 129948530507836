import { act } from "@testing-library/react"

export const Reducer = (state, action) => {
  switch (action.type) {
    case "API_REQUEST_HANDLE":
      return {
        ...state,
        loading: action.payload.loading,
        error: action.payload.error,
        responseData: action.payload.data
      }

    case "toggle_button":
      return {
        ...state,
        active: !state.active
      }
    case "TUNNELS":
      return{...state, tunnelLists: action.payload} 
    case "SHOW_SDWAN":
      return {...state, show_sdwan: action.payload}
    case "DEVICE":
      return {...state, deviceData: action.payload}
    case "GENERAL":
      return {
        ...state,
        general: action.payload
      }  
    case "INTERFACE":
      return {
        ...state,
        interface: action.payload
      }
    case "MAIN_INTERFACE":
      return {...state, mainInterfaceLists:action.payload}  
    case "PATHLABELS":
      return {...state, pathLabels:action.payload}  
    case "ACTIVE_TAB":
      return {...state, activeTab: action.payload}
    case "DHCP":
      return {
        ...state,
        dhcp: action.payload
      }
    case 'OSPF':
      return{
        ...state, ospf:action.payload
      }  
   case 'STATIC_ROUTES':
    return{...state, static_routes: action.payload}
    case 'BGP':
      return {...state, bgp:action.payload}
    case 'FIREWALL':
      return {...state, firewallRules: action.payload}
    case 'ROUTE_FILTERS':
        return {...state, routeFilters:action.payload}    
    case "RESERVATION":
      return {
        ...state,
        reservations: action.payload
      }
    case "options":
      return {
        ...state,
        dhcpOptions: action.payload
      }  
    case "CLEARDEVICE":
      return initialState

    default:
      return state
  }
}

export const initialState = {
  active: false,
  general: null,
  interface: null,
  dhcp: null,
  reservations: null,
}
export default Reducer;