import React from 'react';
import somethingError from '../assets/images/something-error-img.jpg'
import styled from '@emotion/styled';
import { Button } from 'antd';


const StyledContainer = styled.div`
    height: 100%;
    width: 100%;
    .error-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .error{
      width: 480px; //40
      height: 400px; //70
    }

    .error-msg{
      font-size: 1.2rem;
      font-weight: 900;
      color: #333;
      letter-spacing: 0.5px;
    }
    .reload{
      width: 100%;
      position: absolute;
      left: 57%;
      top:100%;
    }
`

const ErrorboundaryMessage = ({resetErrorBoundary }) => (
  <StyledContainer role="alert">
    <div className='error-container'>
      <div className='error'><img src={somethingError} width='100%' height='100%'/></div>
      <p className='error-msg'>Something went wrong. Try again later</p>
      {/* <div className='reload'><Button type='primary' onClick={resetErrorBoundary}>Reload</Button></div> */}
    </div>
  </StyledContainer>
);

export default ErrorboundaryMessage;
