import React from 'react';
import { Tree, Popconfirm } from 'antd';
import { PlusCircleOutlined, CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

const { TreeNode } = Tree;

const AdminTreeMenu = ({ menuItems, onSubMenuClick, onRemoveSubMenu, onSelectMenuItem }) => {

    const customSwitcherIcon = ({ isLeaf, expanded }) => {
        if (isLeaf) {
            return null; // Return null for leaf nodes without an icon
        }
        return expanded ? <CaretDownOutlined /> : <CaretRightOutlined />;
    };

    const renderMenuItems = (items, onSubMenuClick, onRemoveSubMenu, onExpand, parentKey = 'menu') => {
        const handleMenuItemClick = (menuItem) => {
            // Call the onSelectMenuItem callback with the selected menu item
            onSelectMenuItem(menuItem);
        };

        return items.map((menuItem, index) => (
            <TreeNode
                switcherIcon={customSwitcherIcon}
                key={`${parentKey}-${index}`}
                title={
                    <div className="title-container" onClick={() => handleMenuItemClick(menuItem)}>
                        <span className="title">{menuItem.title}</span>
                        <PlusCircleOutlined
                            className="add-sub-icon add-sub-button"
                            onClick={() => onSubMenuClick(menuItem)}
                            style={{
                                color: menuItem.children.length >= 3 ? 'gray' : 'blue', // Adjust icon color as needed
                                cursor: menuItem.children.length >= 3 ? 'not-allowed' : 'pointer', // Change cursor style
                            }}
                        />
                        <Popconfirm
                            title="Are you sure you want to remove this submenu?"
                            onConfirm={() => onRemoveSubMenu(menuItem, menuItem.title)}
                            okText="Yes"
                            cancelText="No"
                        />
                    </div>
                }
                selectable={false}
                onExpand={(expanded, nodeInfo) => onExpand(expanded, nodeInfo, menuItem)}
            >
                {menuItem.children && menuItem.children.length > 0
                    ? renderMenuItems(menuItem.children, onSubMenuClick, onRemoveSubMenu, onExpand, `${parentKey}-${index}`)
                    : null}
            </TreeNode>
        ));
    };





    const handleExpand = (expandedKeys, { expanded }, menuItem) => {
        if (expanded) {
            // You can add logic to load or fetch data for the expanded parent menu here if needed
        }
    };

    return (
        <Tree
            defaultExpandAll
            showLine
            onExpand={(expandedKeys, info) => handleExpand(expandedKeys, info, menuItems)}
        >
            {renderMenuItems(menuItems, onSubMenuClick, onRemoveSubMenu, handleExpand)}
        </Tree>
    );
};

export default AdminTreeMenu;


