export let url = process.env.REACT_APP_API_ENDPOINT;
// let url = "https://localhost:3443/api/";
export let integration_url = process.env.REACT_APP_INTEGRATION_ENDPOINT;
//let integration_url="http://localhost:4000/"

export let orchUrl = process.env.REACT_APP_ORCH_ENDPOINT;

export const LOGIN = url + 'users/login';
export const PERMISSION = `${integration_url}organizations/permission`
export const GENERATE_OTP = `${integration_url}billing/generateotp`
export const VERIFY_OTP = `${integration_url}billing/verifyotp`;
export const APPLICATION_STATUS = `${integration_url}billing/applicationstatus`;
export const APPLICATION_USER_ORGANIZATION = `${integration_url}organizations`
export const APPLICATION_ACTION = `${integration_url}organizations/approval-action`;
export const SUBSCRIPTION = `${integration_url}subscriptions`
export const GET_MARKET_SERVICES = `${orchUrl}/api/billing/service`
export const GET_USER = url + 'accounts';
export const GET_ORGANIZATIONS_ORIG = url + 'organizations';
export const GET_ORGANIZATIONS = orchUrl + '/api/billing/org';
export const DEVICE_TOKEN = url + 'tokens';
export const GET_ALL_USERS = url + 'members';
export const GET_DEVICES = url + 'devices';
export const GET_PATHLABELS = url + 'pathlabels';
export const TUNNELS = url + 'tunnels';
export const JOBS = url + 'jobs';
export const RESET_PASSWORD = url + 'users/reset-password';
export const GET_ALL_GROUPS = url + 'members/options/group';
export const GET_ALL_ACCOUNTS = url + 'members/options/account';
export const GET_ALL_ORGANIZATIONS = url + 'members/options/organization';
export const GET_APP_IDENTIFIERS = url + 'appidentifications';
export const NOTIFICATIONS = url + 'notifications';
export const ACCESS_TOKENS = url + 'accesstokens';
export const PATH_TAG = url + 'pathlabels';
export const FIREWALL = url + 'firewallpolicies';
export const VERSIONS = url + 'version';
export const POLICY = url + 'mlpolicies';
export const FIREWALL_POLICY = url + 'firewallpolicies/list';
export const QOS_POLICY = url + 'qosPolicies/list';
export const PEERS = url + 'peers';
export const GET_AVAILABLE_LIST = `${url}appstore`
export const GET_INSTALLED_LIST = `${url}appstore/purchased`
export const QOS_POLICIES = url + 'qosPolicies';
export const QOS_TRAFFIC_MAP = url + 'qosTrafficMap';

export const ZOHO_ACCESS_TOKEN = '1000.ff7abf034d896382e30a7c3523d20ce4.2105bf6bc2bbaad75f7f05d98c3096ef';
export const LIST_TICKETS_URL = integration_url + 'tickets';
export const JWT_TOKEN_URL = integration_url + 'tickets/gtoken';
export const REFRESH_TOKEN_URL = integration_url + 'tickets/rtoken';
export const CREATE_TICKET_URL = integration_url + 'tickets/createTicket';
export const TICKET_HISTORY_URL = integration_url + 'tickets/ticketHistory';
export const TICKET_CONVERSATION_URL = integration_url + 'tickets/listTicketConversation';
export const CLOSE_TICKET_URL = integration_url + 'tickets/closeTickets';
export const SEND_REPLY_URL = integration_url + 'tickets/sendReply';

export const GET_IP_URL = integration_url + 'reports/getip';
export const SAVE_IP_URL = integration_url + 'reports/saveip';

export const GET_FIREWALL_HISTORY_URL = integration_url + 'reports/getfirewallhistory';
export const GET_DEVICE_STATS_URL = integration_url + 'reports/getdevicestats';
export const GET_PRODUCT_VIDEOS = `${integration_url}training/videos`

// ZOOM GET Api's
export const GET_ZOOM_MEETINGS = `${integration_url}zoomcloud/ds/meetings`
export const GET_ZOOM_MEETINGS_ROOMS = `${integration_url}zoomcloud/ds/meetings/rooms`
export const GET_ZOOM_WEBINAR = `${integration_url}zoomcloud/ds/webinar`
export const GET_ZOOM_USERS = `${integration_url}zoomcloud/ds/users`
export const GET_ZOOM_DAILY = `${integration_url}zoomcloud/reports/ds/daily`
export const GET_ZOOM_MONTH = `${integration_url}zoomcloud/reports/ds/month`
export const GET_ZOOM_SUMMARY = `${integration_url}zoomcloud/ds/users/summary`
export const GET_ZOOM_ACCOUNT_USERS = `${integration_url}zoomcloud/subaccount/users`
export const GET_ZOOM_PLAN_BILLINGS = `${integration_url}zoomcloud/subaccount/billing`
export const GET_ZOOM_PLANS = `${integration_url}zoomcloud/subaccount/plans`
export const UPGRADE_BASE_PLAN = `${integration_url}zoomcloud/subaccount/plans/base`
export const UPGRADE_ADD_ONS = `${integration_url}zoomcloud/subaccount/plans/addons`
export const GET_ZOOM_MEETINGS_LIST = `${integration_url}zoomcloud/subaccount/mgmt/meetings`
export const GET_ZOOM_USERS_PLANS = `${integration_url}zoomcloud/subaccount/users/plans`
export const GET_ZOOM_USERS_ACTIVE_PLANS = `${integration_url}zoomcloud/subaccount/users`
export const GET_UPCOMING_RENEWALS = `${integration_url}zoomcloud/account/renewals`;
export const GET_ACCOUNTS_LIST = `${integration_url}zoomcloud/accounts/list`;
export const GET_REPORT_LOGS = `${integration_url}zoomcloud/subaccount/reports/logs`;


//ZOOM Meeting
export const REACT_APP_ZOOM_MSDK_SIGNATURE_ENDPOINT = `${integration_url}zoomcloud/msig`
export const REACT_APP_ZOOM_LEAVE_URL = `/zoom-dashboard`;

//ZOOM SAVE Api's
export const SAVE_ZOOM_USERS = `${integration_url}zoomcloud/subaccount/users`;
export const GET_ZOOM_INSTANT_MEETING = `${integration_url}zoomcloud/subaccount/meetings`

// orch url exports
export const VNC_CONSOLE = `${orchUrl}/api/console`
export const REGISTER_DEVICE = `${orchUrl}/api/device`;
export const DEVICE_BULK_UPLOAD = `${orchUrl}/api/device/bulk`
export const LIST_DEVICES = `${orchUrl}/api/device/quickview`;
export const AVAILABLE_IMAGES = `${orchUrl}/api/image`;
export const DEVICE_IMAGES = `${orchUrl}/api/image/device`;
export const ADMIN_SHELL = `${orchUrl}/api/shell`;
export const DEVICE_QUICK_LIST = `${orchUrl}/api/device/list`;
export const SUBMIT_SHELL_DATA = `${orchUrl}/api/shell`;
export const VERIFY_JOB = `${orchUrl}/api/job`;
export const DEVICE_LOG_GROUPS = `${orchUrl}/api/device/groups`;
export const DEVICE_LOG_DATA = `${orchUrl}/api/log/`;
export const VNF_MANAGE_LIST_VMS = `${orchUrl}/api/command`;
export const PROVISION_SERVICE = `${orchUrl}/api/provision/service`;
export const PROVISION = `${orchUrl}/api/provision/`;
export const ACCOUNT_SERVICES = `${orchUrl}/api/billing/account/service`;
export const STATISTICS = `${orchUrl}/api/statistics`;
export const VNF_INFO = `${orchUrl}/api/provision/vnf/`;
export const VNF_REPORTS = `${orchUrl}/api/reports/orgvnf`;
export const AGENT_VERSION = `${orchUrl}/api/settings/version/agent`;
export const IP_MGMT = `${orchUrl}/api/reports/ipmgmt`;
export const NETWORK_CONFIG = `${orchUrl}/api/network/config/`;
export const VM_STORAGE = `${orchUrl}/api/storage`;
export const TEMPERATURE_URL = `${orchUrl}/api/statistics/temperature/`
