import React, { useEffect, useRef, useState } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Image from '@editorjs/image';
import Code from '@editorjs/code';
import Table from '@editorjs/table';
import Embed from '@editorjs/embed';
import LinkTool from '@editorjs/link';
import { Button } from 'antd';

const MenuItemContent = ({ menuItem }) => {
    const [contents, setContents] = useState([]);
    const [nextId, setNextId] = useState(1);

    const editorRef = useRef(null);

    useEffect(() => {
        // Initialize the Editor.js instance when the component mounts
        editorRef.current = new EditorJS({
            holder: `editorjs-${menuItem.title}`,
            tools: {
                header: Header,
                list: List,
                image: Image,
                code: Code,
                table: Table,
                Embed: Embed,
                LinkTool: LinkTool
            },
            data: getContentByMenuItemTitle(menuItem.title), // Set initial content
        });

        return () => {
            // Cleanup when the component unmounts
            if (editorRef.current) {
                // Destroy the Editor.js instance
                editorRef.current.destroy();
            }
        };
    }, [menuItem]);

    // Function to retrieve content by menuItem title
    function getContentByMenuItemTitle(title) {
        const content = contents.find((item) => item.name === title);
        return content ? content.outputData : null;
    }

    function saveContent() {
        if (editorRef.current) {
            editorRef.current
                .save()
                .then((outputData) => {
                    console.log(menuItem);
                    console.log('outputData: ', outputData);
                    let parent_Id = 0; // Change to let so you can modify it
                    let found = false;

                    if (contents && contents.length > 0) {
                        contents.forEach((item) => {
                            if (item.menuItem.children && item.menuItem.children.length > 0) {
                                const content = item.menuItem.children.find(
                                    (child) => child.title === menuItem.title
                                );
                                if (content) {
                                    parent_Id = item.id;
                                    found = true;
                                    console.log("parent_Id " + parent_Id)
                                }
                            }
                        });
                    }

                    // Check if the menuItem.title already exists
                    const existingIndex = contents.findIndex(
                        (content) => content.name === menuItem.title
                    );

                    if (existingIndex !== -1) {
                        // If exists, update the existing content
                        const updatedContents = [...contents];
                        updatedContents[existingIndex] = {
                            ...updatedContents[existingIndex],
                            outputData: outputData,
                        };
                        setContents(updatedContents);
                    } else {
                        // If not exists, create a new content object
                        const contentJSON = {
                            id: nextId,
                            name: menuItem.title,
                            menuItem: menuItem,
                            outputData: outputData,
                            parentId: parent_Id,
                        };

                        // Update the state with the new content
                        setContents((prevContents) => [...prevContents, contentJSON]);
                        setNextId(nextId + 1);
                    }

                    console.log("contents ", contents);
                })
                .catch((error) => {
                    console.log('Error', error);
                });
        }
    }



    return (
        <div>
            <div className="nest-btn">
                <h2>{menuItem.title}</h2>
                <Button
                    className="add-sub-button"
                    type="primary"
                    size="small"
                    onClick={saveContent}
                >
                    Save
                </Button>
            </div>
            <div id={`editorjs-${menuItem.title}`}></div>
        </div>
    );
};

export default MenuItemContent;
