import { PlusCircleOutlined } from '@ant-design/icons';
import { Input, Layout, Modal } from 'antd';
import React, { useState } from 'react';
import AdminTreeMenu from './AdminTreeMenu';
import MenuItemContent from './MenuItemContent';

const { Sider, Content } = Layout;

const HelpLayout = () => {
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [newMenuItem, setNewMenuItem] = useState('');
    const [menuItems, setMenuItems] = useState([]);

    const [isSubMenuDialogVisible, setIsSubMenuDialogVisible] = useState(false);
    const [newSubMenu, setNewSubMenu] = useState('');
    const [currentMenuItem, setCurrentMenuItem] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

    const handleOpenDialog = () => {
        setIsDialogVisible(true);
    };

    const handleCloseDialog = () => {
        setIsDialogVisible(false);
        setNewMenuItem('');
    };

    const handleSaveMenuItem = () => {
        setMenuItems([...menuItems, { title: newMenuItem, children: [] }]);
        handleCloseDialog();
    };

    const handleOpenSubMenuDialog = (menuItem) => {
        // Check if there are fewer than 3 existing submenus for the current parent menu item
        if (menuItem.children.length < 3) {
            setCurrentMenuItem(menuItem);
            setIsSubMenuDialogVisible(true);
        } else {
            console.log("test sub")
            // Display a message or notification that you can't add more submenus
        }
    };

    const handleCloseSubMenuDialog = () => {
        setCurrentMenuItem(null);
        setIsSubMenuDialogVisible(false);
        setNewSubMenu('');
    };

    const handleSaveSubMenu = () => {
        if (currentMenuItem) {
            currentMenuItem.children.push({ title: newSubMenu, children: [] });
            setCurrentMenuItem({ ...currentMenuItem });
        }
        handleCloseSubMenuDialog();
    };

    const handleRemoveSubMenu = (parentMenuItem, submenuTitle) => {
        if (parentMenuItem) {
            parentMenuItem.children = parentMenuItem.children.filter(
                (child) => child.title !== submenuTitle
            );
            // parentMenuItem.children = parentMenuItem.filter(
            //     (child) => child.title == submenuTitle
            // );
            setCurrentMenuItem({ ...parentMenuItem });
        }
    };

    return (
        <Layout style={{ minHeight: '100vh', display: 'flex' }}>
            <Sider width={200} theme="dark">
                <div className="add-button-container" style={{ justifyContent: 'space-between' }}>
                    {/* <Button type="primary" onClick={handleOpenDialog}>
                        Add
                    </Button> */}
                    <span className="title">Pages</span>
                    <PlusCircleOutlined
                        className="add-sub-icon add-sub-button"
                        onClick={handleOpenDialog}

                    />
                </div>
                <AdminTreeMenu
                    menuItems={menuItems}
                    onSubMenuClick={handleOpenSubMenuDialog}
                    onRemoveSubMenu={handleRemoveSubMenu}
                    onSelectMenuItem={setSelectedMenuItem} // Pass the setSelectedMenuItem function
                />
            </Sider>
            <Layout>
                <Content>
                    <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
                        {selectedMenuItem ? (
                            <MenuItemContent menuItem={selectedMenuItem} />
                        ) : (
                            <h1></h1>
                        )}
                    </div>
                </Content>
            </Layout>
            <Modal
                title="Add Page Name"
                visible={isDialogVisible}
                onOk={handleSaveMenuItem}
                onCancel={handleCloseDialog}
            >
                <Input
                    placeholder="Enter Page Name"
                    value={newMenuItem}
                    onChange={(e) => setNewMenuItem(e.target.value)}
                />
            </Modal>
            <Modal
                title="Add Sub Page"
                visible={isSubMenuDialogVisible}
                onOk={handleSaveSubMenu}
                onCancel={handleCloseSubMenuDialog}
            >
                <Input
                    placeholder="Enter sub page"
                    value={newSubMenu}
                    onChange={(e) => setNewSubMenu(e.target.value)}
                />
            </Modal>
        </Layout>
    );
};


export default HelpLayout;
