import * as XLSX from 'xlsx';
import moment from "moment";
import CUPS from '../assets/images/available-images/CUPS.png';
import ipfire4 from '../assets/images/available-images/ipfire4.jpeg';
import _5gueran from "../assets/images/available-images/ipfire4.png";
import jitsi from '../assets/images/available-images/jitsi.png';
import kloudspot from '../assets/images/available-images/kloudspot.png';
import pfsense from '../assets/images/available-images/pfsense.png';
import sbcc from '../assets/images/available-images/sbcc.png';
import sbcr from '../assets/images/available-images/sbcr.png';
import sdwan from '../assets/images/available-images/sdwan.png';
import tacitine from '../assets/images/available-images/tacitine.png';
import telaverge from '../assets/images/available-images/telaverge.png';
import thingsBoard from '../assets/images/available-images/thingsboard_blue.png';
import twag from '../assets/images/available-images/twag.png';
import ubuntuWithoutBg from '../assets/images/available-images/ubuntu-removebg.png';
import upf5g from '../assets/images/available-images/UPF5G.png';
import win2k22 from '../assets/images/available-images/win2k22.png';
import windows from '../assets/images/available-images/windows.svg';
import zoom from '../assets/images/available-images/zoom.png';
import azure from '../assets/images/available-images/azure.png';
import aws from '../assets/images/available-images/aws.png';
import googleCloud from '../assets/images/available-images/google-cloud.png';
import docker from '../assets/images/available-images/docker.jpg';

export function ValidateEmailId(keyValue) {
    let emailMsg = "";
    if ((keyValue !== '')) {
        if (/[a-zA-Z0-9]{1,15}@[a-zA-Z0-9]{1,15}[.][a-zA-Z]{2,5}/.test(keyValue)) {
            console.log('success');
        }
        else {
            emailMsg = "Please enter valid email!"
        }
    }
    return emailMsg;
}
export function ValidatePassword(keyValue) {
    let passwordMsg = "";
    if (keyValue !== '') {
        if (keyValue.length < 7)
            passwordMsg = 'Password should have minimum 8 characters!';
    }
    return passwordMsg;
}

export function getUserToken() {
    var token = "";
    var currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
        currentUser = JSON.parse(currentUser);
        token = currentUser.token;
    }
    return token;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isValidIP(string) {
    var expression = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;

    if (expression.test(string)) {
        // good IP
        return true
    } else {
        // bad IP
        return false
    }
}

export function convertToInternationSystem (labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs(Number(labelValue));
}

export const camelCaseStrings = (string = "", separator = " ") => {
    const strArr = string.split(separator);

    for (let i = 0; i < strArr.length; i++) {
        strArr[i] = strArr[i][0].toUpperCase() + strArr[i].substr(1);
    }
    
    return strArr.join(" ") ?? "";
}

export const getDateFromString = (dateStr) => {
    var date = "";

    if(dateStr) {
        date = new Date(dateStr).toLocaleDateString('en-GB');
    }

    return date;
}

export const getTimeFromString = (dateStr) => {
    var time = "";

    if(dateStr) {
        time = new Date(dateStr).toLocaleTimeString('en-GB');
    }

    return time;
}

export const convertMemoryReable = (kilobytes, decimals = 2) => {
    if (kilobytes === 0) return '0 kilobytes';
  
    const k = 1024;
    const sizes = ['kilobytes', 'MB', 'GB', 'TB'];
  
    const i = Math.floor(Math.log(kilobytes) / Math.log(k));
  
    return parseFloat((kilobytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
}


export const requestHeaders = () => {
    return {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${getUserToken()}`,
        },
    }
}

export const getReadableFormatDateTime = (dateTime, time=true) => {
    // const dateObject = moment(dateTime);
    // const formattedDateTime = dateObject.format('DD/MM/YYYY HH:mm:ss');
    
    const formattedDate = moment(dateTime).format(time ? 'DD-MMM-YYYY HH:mm:ss' : 'DD-MMM-YYYY').toUpperCase();

    return formattedDate
}


export const encodeStr = (str) =>  btoa(str);
export const decodeStr = (str) =>  atob(str); 

export const convertMBtoGB = (mb) => {
    const gb = mb / 1024; // 1 GB = 1024 MB
    const value = Math.round(gb * 10) / 10
    return `${value}GB`;
}

export const excelTemplateFileDownload = (download_name, datas) => {
    if(datas?.length > 0){
        let workbook; 
        for(let i= 0; i < datas.length; i++){
            if(datas.length === 1){
                const worksheet = XLSX.utils.json_to_sheet(datas[i]);
                workbook =  XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            }
            if(datas.length === 2){
                const worksheet1 = XLSX.utils.json_to_sheet(datas[0]);
                const worksheet2 = XLSX.utils.json_to_sheet(datas[1]);
                workbook =  XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet1, 'App Records');
                XLSX.utils.book_append_sheet(workbook, worksheet2, 'Interface Records')
            };

        }
        XLSX.writeFile(workbook, `${download_name}.xlsx`);        
    }
}  
 
export const findImage = (image) => {
    let asset;

    if (image === 'printer') {
      asset = CUPS;
    } else if (image === 'pfsense') {
      asset = pfsense;
    } else if (image === 'sdwan' || image === 'svb' || /single/gi.test(image) || /sdwan/gi.test(image)) {
      asset = sdwan;
    } else if (image === 'sbcc') {
      asset = sbcc;
    } else if (image === 'sbcr') {
      asset = sbcr;
    } else if (image?.includes('ubuntu')) {
      asset = ubuntuWithoutBg;
    } else if (image?.includes('windows2012')) {
      asset = windows;
    } else if (image === 'tacitine') {
      asset = tacitine;
    } else if (image === 'twag') {
      asset = twag;
    } else if (/zoom/gi.test(image)) {
      asset = zoom;
    } else if (image === 'upf5g') { 
      asset = upf5g;
    } else if (image?.includes('windows2022')) { 
      asset = win2k22;
    } else if (image === 'thingsboard') {
      asset = thingsBoard;
    } else if (image === '5gupf' || image === '5g5gc') {
      asset = telaverge;
    } else if (image === 'ipfire4') {
      asset = ipfire4;
    } else if (image === 'kdsfirewall' || image === 'kdsems' || (/kloud/gi.test(image))) { 
      asset = kloudspot;
    } else if (image === 'jitsi') {
      asset = jitsi;
    } else if (image === '5gueran') {
      asset = _5gueran;
    }else if (/google/gi.test(image)){
      asset = googleCloud;
    }else if (/azure/gi.test(image)) {
      asset = azure;
    }else if (/aws/gi.test(image)) {
      asset = aws;
    }else if (/docker/gi.test(image)) {
      asset = docker;
    }

    return asset;
  };


  export const getSubscriptionDetails = (type= '') => {
  //   {
  //     "_id": "6620e2cbcaea9256383d999e",
  //     "accountId": "65dc456a8fd825b3bca9e65a",
  //     "appname": "svb",
  //     "autoRenewal": true,
  //     "createdAt": "2024-05-08T07:09:24.630347+00:00",
  //     "endDate": "2024-05-31",
  //     "license": "10",
  //     "orgId": "6634f77eed35a0801d44a0a5",
  //     "orgName": "QHTLABS",
  //     "productId": "6620e2cbcaea9256383d999e",
  //     "services": [
  //         "657ff75a1cec1d71a6cf0caa",
  //         "657ff6dc1cec1d71a6cf0c9e",
  //         "657ff6d41cec1d71a6cf0c9d"
  //     ],
  //     "servicesubtype": "virtual",
  //     "servicetype": "ictsaas",
  //     "startDate": "2024-05-08",
  //     "subscriptionEnabled": true,
  //     "updatedAt": "2024-05-08T07:09:24.630347+00:00"
  // }
    const subscriptionDetails = localStorage.getItem('subscriptionDetails');
    const servicesList = localStorage.getItem('marketServicesList');
        let getSubscriptionData = [];
        let getServicesList = [];

        if(subscriptionDetails) getSubscriptionData = JSON.parse(subscriptionDetails);
        if(servicesList) getServicesList= JSON.parse(servicesList);
        
        const subscriptionMenus = [];
        const subsIDList = [];
        if(getSubscriptionData?.length > 0){
            getSubscriptionData.forEach((subs_list) => {
                if(subs_list.appname === 'svb' && !subscriptionMenus.includes(subs_list.appname)) {
                  subscriptionMenus.push(subs_list.appname);
                  subsIDList.push(subs_list._id);
                }  

                if((subs_list.appname === 'sdwan' && subs_list.servicetype === 'ictsaas') || subs_list.appname === 'svb'){    
                  if(getServicesList?.length > 0){
                    getServicesList.forEach((all_services) => {
                          // console.log(subs_list.services?.includes(all_services._id), all_services.appname)   
                            if(subs_list.services?.includes(all_services._id) && all_services.appname === 'sdwan' && !subscriptionMenus.includes(all_services.appname)){
                              subscriptionMenus.push(all_services.appname);
                              subsIDList.push(all_services._id); 
                            };
                        })  
                    }
                    else { 
                      subscriptionMenus.push(subs_list.appname);
                      subsIDList.push(subs_list._id);
                    }
                }

                if(subs_list.appname === 'zoom' && !subscriptionMenus.includes(subs_list.appname)){
                  subscriptionMenus.push(subs_list.appname);
                  subsIDList.push(subs_list._id);
                };
            })  
        };
        return type === 'id' ? subsIDList : subscriptionMenus;
  };


  export function convertToTimeZones(utcTime, timeZone) {
    // const timeZones = [
    //     'America/Los_Angeles', // PST
    //     'America/Denver',      // MST
    //     'America/Chicago',     // CST
    //     'America/New_York',    // EST
    //     'Europe/London',       // GMT
    //     'Europe/Berlin',       // CET
    //     'Europe/Athens',       // EET
    //     'Asia/Kolkata',        // IST
    //     'Asia/Shanghai',       // CST (China)
    //     'Asia/Tokyo',          // JST
    //     'Australia/Perth',     // AWST
    //     'Australia/Adelaide',  // ACST
    //     'Australia/Sydney',    // AEST
    //     'Pacific/Auckland'     // NZST
    // ];
    // return timeZones.map(timeZone => {
        const localTime = moment.tz(utcTime, timeZone).format('YYYY-MM-DD HH:mm:ss');
        return localTime;
}

export const ConvertFirstLetterUppercase = (value) => {
  const key = String(value);
  return key && key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};


